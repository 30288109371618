import gql from 'graphql-tag';

// export const GETBALANCE = gql`query getBalance{
//     getBalance{     
//       status_code
//       status_message
//       result{
//         image
//         currency_code
//         currency_name
//         currency_type
//         deposit_status
//         withdraw_status
//         defaultCryptoValue
//         USDValue
//         trade_status
//         available_balance
//         inorder_balance
//         total_balance
//         BTCValue
//         status
//       }  
//     }
//   }`

  export const GETBALANCE = gql`query getBalance($data: getBalanceInput){
    getBalance(getBalanceData: $data){     
      status_code
      status_message
      result{
        image
        currency_code
        currency_name
        currency_type
        deposit_status
        withdraw_status
        trade_status
        available_balance
        inorder_balance
        total_balance
        stake_balance
        BTCValue
        USDValue
        defaultCryptoValue
        status
      }  
    }
  }`