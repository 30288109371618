import gql from 'graphql-tag';

export const VERIFYEMAIL = gql`mutation($data: verifyUserInput!){
    verifyEmail(verifyUserInput: $data){
      status_code
      status_message
    }
  }`

export const ACTIVATE_ACCOUNT = gql`mutation ($data: NewDeviceInput){
    activateAccount(NewDeviceInput: $data){
      status_code
      status_message
      result{
        token
        email
        account_status
        token_type
      }
    }
  }`

export const AUTHORIZE_DEVICE = gql`mutation ($data: NewDeviceInput!){
    verifyNewDevice(NewDeviceInput: $data) {
      status_code
      status_message
      result{
        token
        email
        session_id
      }
    }
  }`


export const RESEND_EMAIL = gql`mutation ($data: reSendEmailInput){
  resendEmail(reSendEmailData: $data) {
    status_code
    status_message
  }
}`

export const UPDATE_RESEND_EMAIL = gql`mutation ($data: resendUpdateEmailInput){
  resendUpdateEmail(resendUpdateEmailData: $data) {
    status_code
    status_message
  }
}`

export const disableTFA = gql`mutation disableTFA($data: disableTFAInput) {
  disableTFA(disableTFAData: $data) {
    status_code
    status_message
  }
}`