
import gql from 'graphql-tag';

export const getCMS = gql`
query getCMS($CMSQueryData: getCMSInput) {
    getCMS(getCMSData: $CMSQueryData) {
      status_code
      status_message
      result {
        data {
          _id
          lang {
            name
            content
            lang_code
          }
          url
          footer
          type
          status
          created_date
          modified_date
        }
        total
        limit
        page
        pages
      }
    }
  }`;